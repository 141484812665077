<script setup>
import { useSlots, ref, provide } from 'vue';

const props = defineProps({
  selectedTab: String,
  tabClazz: String,
  activeClazz: String,
  headerClazz: String
});

const allTabs = ref(useSlots().default().filter(tab => tab.type.name === 'tab-item'));
const tabTitles = ref(allTabs.value.map(tab => tab.props.title));
const selectedTitle = ref(tabTitles.value[props.selectedTab]);

provide('selectedTitle', selectedTitle);
</script>

<template>
  <div>
    <div v-bind:class="headerClazz">
      <div v-for="title in tabTitles"
        v-bind:key="title"
        @click="selectedTitle = title"
        v-bind:class="[tabClazz, isActive(selectedTitle === title)]"
        v-cloak
      >
        {{ title }}
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  methods: {
    isActive (value) {
      if (value)
        return this.activeClazz;
    }
  }
};
</script>
